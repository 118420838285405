import React from 'react';
import CustomPopup from './custom-popup';
//import SubscriptionPopup from './subscription-popup';
import GdprFooter from "./gdpr-footer";

const PopupContentWrapper = ({ children }) => (
  <>
    {children}
    {/* <SubscriptionPopup /> */}
    <CustomPopup />
    <GdprFooter />
  </>
);

export default PopupContentWrapper;