import React, {useState, useEffect} from "react";
import { Link } from "gatsby";

import * as styles from "../styles/components/gdpr-footer.module.css";

const GdprFooter = () => {
    const [showGdprFooter, setShowGdprFooter] = useState(true)
    const parseCookie = str =>
    str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
    useEffect(() => {
        const cookie = document.cookie ? parseCookie(document.cookie): {};
        if (cookie.cookiesAccepted) {
            setShowGdprFooter(false)
        }
    }, []);

    const setCookiesAccepted = () => {
        const cname = 'cookiesAccepted';
        const cvalue = 'true';
        const d = new Date();
        d.setTime(d.getTime() + 7776000000);
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
        setShowGdprFooter(false);
    }
    if (showGdprFooter) {
        return (
            <div className={styles.root}>
                <p className={styles.textContainer}>
                    We use cookies to improve Chain.io's website user experience. By clicking "Accept cookies" you agree to the storing of cookies on your device to enhace our website navigation, analyze website usage, and assist in our marketing efforts. <Link to="/privacy">Learn more</Link>.
                </p>
                <div className={styles.buttonContainer}>
                    <button onClick={setCookiesAccepted} className="btn">Accept cookies</button>
                </div>
            </div>
        )
    } 
    else return null

}

export default GdprFooter;