import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import * as styles from "../styles/components/custom-popup.module.css";

const query = graphql`
  query PopupQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      popupBanner {
        text
        backgroundImage {
            asset {
                url
            }
        }
        button {
            text
            url
        }
        isVisible
      }

    }
  }
`;

const CustomPopup = () => {
    const [isCustomPopupVisible, setIsCustomPopupVisible] = useState(false);
    const [isCustomPopupDismissed, setIsCustomPopupDismissed] = useState(false);
    const closeCustomPopup = () => {
        setIsCustomPopupVisible(false)
        setIsCustomPopupDismissed(true)
    }
    useEffect(() => {
        const popedUpCustom = sessionStorage.getItem('popedUpCustom')
        const timer = setTimeout(() => {
            if (!isCustomPopupDismissed && !popedUpCustom) {
                setIsCustomPopupVisible(true)
                sessionStorage.setItem('popedUpCustom', 'true');
            }
        }, 10000);
        return () => clearTimeout(timer)
    }, [isCustomPopupDismissed]);
    if (isCustomPopupVisible) {
        return (
            <StaticQuery
                query={query}
                render = {({site: {popupBanner}}) => popupBanner.isVisible && (
                    <div className={styles.root}>
                            <div className={styles.content}>
                                <p className={styles.text}>{popupBanner?.text}</p>
                                <div className={styles.buttonContainer} style={{'background-image': `linear-gradient(to right, black 0.1%, rgba(255, 255, 255, 0) 100%), url("${popupBanner?.backgroundImage.asset.url}")`}}>
                                    <div className={styles.closeContainer}>
                                        <button onClick={closeCustomPopup} className={styles.closeButton}>x</button>
                                    </div>
                                    <div className={styles.linkContainer}>
                                        <a className={styles.link} target="_blank" rel="noreferrer" href={popupBanner?.button.url}>{popupBanner?.button.text}</a>
                                    </div>
                                </div>
                            </div>
                    </div>
                )}
            />
        )
    }
    else return null;
}

export default CustomPopup;