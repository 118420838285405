exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-company-about-us-js": () => import("./../../../src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-contact-us-js": () => import("./../../../src/pages/company/contact-us.js" /* webpackChunkName: "component---src-pages-company-contact-us-js" */),
  "component---src-pages-company-partners-js": () => import("./../../../src/pages/company/partners.js" /* webpackChunkName: "component---src-pages-company-partners-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-connect-js": () => import("./../../../src/pages/open-connect.js" /* webpackChunkName: "component---src-pages-open-connect-js" */),
  "component---src-pages-partner-registration-js": () => import("./../../../src/pages/partner-registration.js" /* webpackChunkName: "component---src-pages-partner-registration-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-plans-js": () => import("./../../../src/pages/product/plans.js" /* webpackChunkName: "component---src-pages-product-plans-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-w-9-js": () => import("./../../../src/pages/w-9.js" /* webpackChunkName: "component---src-pages-w-9-js" */),
  "component---src-templates-demand-page-js": () => import("./../../../src/templates/demandPage.js" /* webpackChunkName: "component---src-templates-demand-page-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-integrations-page-js": () => import("./../../../src/templates/integrations-page.js" /* webpackChunkName: "component---src-templates-integrations-page-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/news-category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-solution-category-js": () => import("./../../../src/templates/solution-category.js" /* webpackChunkName: "component---src-templates-solution-category-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-solutions-page-js": () => import("./../../../src/templates/solutions-page.js" /* webpackChunkName: "component---src-templates-solutions-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

